.hero{
    width: 100%;
    height: auto;
    background-color: #1b1b1b;
}

#video {
    height: 100vh;
    object-fit: fill;
    width: 100%;
    opacity: 0.8;
}

#scroll-btn{
    position: absolute;
    height: 5vh;
    width: 3vh;
    border: 0.2vh solid #ffffff;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 2vw;
    border-radius: 2vw;
}

#scroll-btn:before{
    position: absolute;
    content: "";
    margin: auto;
    left: 0;
    right: 0;
    height: 0.7vw;
    width: 0.7vw;
    background-color: #fff;
    border-radius: 50%;
    animation: move-down 2s infinite;
}

@keyframes move-down{
    80%{
        opacity: 0.5;
    }
    100%{
        transform: translateY(3vh);
        opacity: 0;
    }
}

@media(max-width: 576px){
    #scroll-btn{
        height: 5vh;
        width: 3vh;
        bottom: 5vw;
        border-radius: 4vw;
    }
    #scroll-btn:before{
        height: 2vw;
        width: 2vw;
    }



}

@media(min-width: 576px){
    #scroll-btn{
        height: 5vh;
        width: 3vh;
        bottom: 4vw;
        border-radius: 3vw;
    }
    #scroll-btn:before{
        height: 1.5vw;
        width: 1.5vw;
    }
}

@media(min-width: 768px){
    #scroll-btn:before{
        height: 1.5vw;
        width: 1.5vw;
    }

}

@media(min-width:992px){
    #scroll-btn:before{
        height: 1vw;
        width: 1vw;
    }

}

@media(min-width:1200px) {
    #scroll-btn:before{
        height: 0.7vw;
        width: 0.7vw;
    }


}