.header{
    position: absolute;
    z-index: 110;
    top: 0;
    right: 0;
    margin-right: 6vh;
    margin-top: 6vh;
    display: flex;
    height: 20%;
    width: auto;
}

.position-fixed{
  position:fixed;
}

.navbar{
    position:fixed;
    top:0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 100;
    display: block;
    justify-content: space-between;
    vertical-align: bottom;
    flex-flow: column;
    padding: 20% 15% 25% 10%;
}

.logo-image{
  height: 100%;
  width: auto;
}

.logo{
  height: 100%;
}

.bg {
    width: 100%;
    height: 100vh;
    background-size: 300% 300%;
    background-image: linear-gradient(
          -45deg, 
          rgba(36, 6, 7,1) 0%, 
          rgba(0, 0, 0,1) 20%, 
          rgba(36, 6, 7,1) 51%, 
          rgba(0, 0, 0,1) 100%
    );  
    animation: AnimateBG 20s ease infinite;
  }
  
  @keyframes AnimateBG { 
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
  }

.navbar li{
    list-style:none;
    float: left;
    bottom: 0;
}

.nav-link{
    font-size: 4em;
    font-weight: 300 !important;
    color: #acacac;
    padding-right: 8vw;
    transition: 2s;
    text-decoration: none;}

.nav-link:hover{
    color: #fff;
}

.contact{
    padding: 5vw 0 0 0;
    color: #fff;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .contact-info{
    font-weight: 20px;
    font-weight: 700;
    line-height: 32px;
  }
  
  .contact-detail{
    font-size: 16px;
    line-height: 30px;
    font-weight: 400; 
    color: #fff;
    text-decoration: none;
    transition: all 0.8s;
  }

  .contact-detail:hover{
    color: #c11c11;
  }



.floating1 { 
    animation-name: floating !important;
    animation-duration: 3s !important;
    animation-iteration-count: infinite !important;
    animation-timing-function: ease-in-out !important;
}
.floating2 { 
    animation-name: floating !important;
    animation-duration: 4s !important;
    animation-iteration-count: infinite !important;
    animation-timing-function: ease-in-out !important;
}
.floating3 { 
    animation-name: floating !important;
    animation-duration: 5s !important;
    animation-iteration-count: infinite !important;
    animation-timing-function: ease-in-out !important;
}
 
@keyframes floating {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 3px); }
    100%   { transform: translate(0, -0px); }   
}




.hamburger{
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items:center;
}

.cursor{
  width: 64px;
  height: 64px;
  background-color: #fff;
  border-radius: 50%;
  border: none;
  position: absolute;
  text-align: center;
  vertical-align: middle;
  opacity: 0;
  display: none;
  color: #c11c11;
  font-size: 2vh;
  line-height: 64px;
  pointer-events: none;
  mix-blend-mode: difference;
}

.header-icon{
  color: #fff;
  font-size: 30px;
  transition: all 0.8s;
}

.header-icon:hover{
  color: #c11c11;
}







@media(max-width: 400px){
  .header{
    margin-right: 5vh;
    margin-top: 8vh;
  }

}


@media(max-width: 576px){
  .header{
    margin-right: 5vh;
    margin-top: 5vh;
  }
  .logo-image{
  max-width: 100%;
  }
  .nav-link{
    font-size: 1.6em;
    padding-right: 8vw;
  }
  .navbar{
    padding: 60% 5% 10% 5%;
  }
  .contact{
    padding: 10vw 0 0 0;
    display: inline-block;
  }


}

@media(min-width: 576px){
  .header{
    margin-right: 5vh;
    margin-top: 5vh;
  }
  .nav-link{
    font-size: 2em;
    padding-right: 8vw;
  }
  .navbar{
    padding: 60% 5% 10% 5%;
  }
  .contact{
    padding: 10vw 0 0 0;
    display: inline-block;
  }

}

@media(min-width: 768px){
  .header{
    margin-right: 6vh;
    margin-top: 6vh;
    height: 10%;
  }
  .nav-link{
    font-size: 3em;
    padding-right: 8vw;
  }
  .navbar{
    padding: 40% 5% 10% 5%;
  }
  .contact{
    padding: 10vw 0 0 0;
    display: inline-block;
  }


}

@media(min-width:992px){
  .header{
    margin-right: 5vh;
    margin-top: 6vh;
    height: 10%;
  }
  


}

@media(min-width:1200px) {
  .header{
    margin-right: 6vh;
    margin-top: 2vh;
    height: 20%;
    width: auto;
  }
  .navbar{
    position:fixed;
    height: 100%;
    width: 100%;
    padding: 20% 15% 25% 10%;
  }
  .contact{
    padding: 5vw 0 0 0;
    color: #fff;
    display: flex;
    justify-content: space-between;
    width: 100%;
    }
  
    .contact-info{
    font-weight: 20px;
    font-weight: 700;
    line-height: 32px;
    }
  
  .contact-detail{
    font-size: 16px;
    line-height: 30px;
    font-weight: 400; 
  }


}
