.blog-section1-body{
    width: 100%;
    height: auto;
    background-color: #f0f3ff;
    color: #303030;
    padding: 15vh 18vh 15vh 18vh;
}

.blog-section1-sub{
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
}

#grid {
    display: grid;
    grid-auto-rows: auto;
    grid-auto-columns: auto;
    grid-template-areas: "a a";
}

#grid section:nth-child(even){
    margin-top: 10vh;
}

.blog-all-margin{
    padding-bottom: 10vh;
}

.cursor2{
    width: 80px;
    height: 80px;
    background-color: #C44D4F;
    border-radius: 50%;
    border: none;
    position: absolute;
    text-align: center;
    vertical-align: middle;
    opacity: 0;
    color: #fff;
    font-size: 2vh;
    line-height: 80px;
    transition: all 0.3s;
    pointer-events: none;
}




.blog-section1-title{
    font-weight: 600;
    font-size: 30px;
    line-height: 50px;
}

.blog-nav-links{
    margin-top: 5vh;
    margin-bottom: 2vh;
    font-weight: 500;
    font-size: 18px;
    line-height: 32px;
    color: #CCCCCC;
}


.blog-image-place{
    width: 80%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3vh;
}

.blog-image{
    max-width: 100%;
}

.blog-list-sub{
    font-weight: 600;
    font-size: 20px;
}

.blog-list-title{
    font-weight: 600;
    font-size: 25px;
    line-height: 45px;
    width: 80%;
}

.blog-list-text{
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    width: 80%;
}

hr.blogline{
    border: 1px solid #303030;
    opacity: 1;
    width: 15%;
    display: inline-block;
    margin: auto;
}

@media(max-width: 576px){
    .blog-section1-body{
        height: auto;
        padding: 15vh 5vh 15vh 5vh;
    }

    .blog-image-place{
        width: 90%;
        margin-bottom: 3vh;

    }

    .blog-list-title{
    font-size: 25px;
    line-height: 45px;
    }

    #grid {
        grid-template-areas: "a";
        margin-top: 8vh;
    }

    #grid section:nth-child(even){
        margin-top: 3vh;
    }
    #grid section:nth-child(odd){
        margin-top: 3vh;
    }


}

@media(min-width: 576px){
    .blog-section1-body{
        height: auto;
        padding: 15vh 5vh 15vh 5vh;
    }


    .blog-image-place{
        width: 90%;
        margin-bottom: 3vh;

    }
    #grid {
        grid-template-areas: "a";
        margin-top: 8vh;
    }

    #grid section:nth-child(even){
        margin-top: 3vh;
    }
    #grid section:nth-child(odd){
        margin-top: 3vh;
    }

}

@media(min-width: 768px){
    #grid {
        grid-template-areas: "a";
        margin-top: 8vh;
    }

    #grid section:nth-child(even){
        margin-top: 3vh;
    }
    #grid section:nth-child(odd){
        margin-top: 3vh;
    }


}

@media(min-width:992px){
    #grid {
        grid-template-areas: "a";
        margin-top: 5vh;
    }

    #grid section:nth-child(even){
        margin-top: 3vh;
    }
    #grid section:nth-child(odd){
        margin-top: 3vh;
    }
    .blog-image-place{
        width: 80%;
    }


}

@media(min-width:1200px) {
    .blog-section1-body{
        width: 100%;
        height: auto;
        background-color: #f0f3ff;
        color: #303030;
        padding: 15vh 18vh 15vh 18vh;
    }
    
    .blog-section1-sub{
        font-weight: 600;
        font-size: 18px;
        line-height: 32px;
    }
    
    .blog-section1-title{
        font-weight: 600;
        font-size: 30px;
        line-height: 50px;
    }
    
    .blog-nav-links{
        margin-top: 5vh;
        margin-bottom: 2vh;
        font-weight: 500;
        font-size: 18px;
        line-height: 32px;
        color: #CCCCCC;
    }
    
    
    .blog-image-place{
        width: 80%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 3vh;
    }
    
    .blog-image{
        max-width: 100%;
    }
    
    .blog-list-sub{
        font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    }
    
    .blog-list-title{
        font-weight: 600;
    font-size: 30px;
    line-height: 50px;
    }
    
    .blog-list-text{
        font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    }
    
    hr.blogline{
        border: 1px solid #303030;
        opacity: 1;
        width: 15%;
        display: inline-block;
        margin: auto;
    }
    #grid {
        grid-template-areas: "a a";
        margin-top: 5vh;
    }

    #grid section:nth-child(even){
        margin-top: 10vh;
    }
    #grid section:nth-child(odd){
        margin-top: 3vh;
    }


}