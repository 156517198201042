.blog-hero{
    width: 100%;
    height: 100vh;
    color: #fff;
    background:  url(/src/assets/rec_9.png) no-repeat;
    padding-top: 21vh;
    background-size: cover;
    padding-left: 15vh;
    background-position: center;
}


.blog-header-link-title{
    margin-top: 12vh;
    width: 80%;
    font-size: 60px;
    line-height: 80px;
    font-weight: 400;
    text-align: left;
}

@media(max-width: 576px){
    .blog-hero{
        height: 100vh;
        padding-top: 21vh;
        padding-right: 5vh;
        padding-left: 5vh;
    }

    .blog-header-link-title{
        margin-top: 12vh;
        width: 100%;
        font-size: 40px;
    }


}

@media(min-width: 576px){
    .blog-hero{
        height: 100vh;
        padding-top: 21vh;
        padding-right: 5vh;
        padding-left: 5vh;
    }

    .blog-header-link-title{
        margin-top: 12vh;
        width: 100%;
        font-size: 40px;
    }


}

@media(min-width: 768px){


}

@media(min-width:992px){
    .blog-hero{
        height: 100vh;
        padding-top: 21vh;
        padding-right: 5vh;
        padding-left: 5vh;
    }

    .blog-header-link-title{
        margin-top: 12vh;
        width: 100%;
        font-size: 50px;
    }


}

@media(min-width:1200px) {
    .blog-hero{
        width: 100%;
        height: 100vh;
        color: #fff;
        background:  url(/src/assets/rec_9.png) no-repeat;
        padding-top: 21vh;
        background-size: 100% 100%;
        padding-left: 15vh;
    }
    
    
    .blog-header-link-title{
        margin-top: 12vh;
        width: 80%;
        font-size: 60px;
        line-height: 80px;
        font-weight: 400;
        text-align: left;
    }


}