.portfolio-hero{
    width: 100%;
    height: auto;
    color: #fff;
    background: linear-gradient(to right, rgba(28, 28, 28, 0.9), rgba(28, 28, 28, 0.9)), url(/src/assets/rec_1.png);
    padding-top: 21vh;
    padding-left: 15vh;
    background-size: cover;
}

.hero-header-link{
    text-decoration: none;
    color: #fff;
    font-size: 18px;
    line-height: 32px;
    font-weight: 400;
}

.hero-header-link-bold{
    font-weight: 600;
}

.hero-header-link:hover{
    color: #c11c11;
}

.hero-header-slash{
    margin-left: 2vh;
    margin-right: 2vh;
    display: inline-block;
}

.hero-header-link-title{
    margin-top: 12vh;
    width: 80%;
    font-size: 60px;
    line-height: 80px;
    font-weight: 400;
    text-align: left;
}

@media(max-width: 576px){
    .portfolio-hero{
        padding: 20vh 5vh 25vh 5vh;
    }
    .hero-header-link-title{
        margin-top: 15vh;
        width: 100%;
        font-size: 40px;
        line-height: 50px;
    }



}

@media(min-width: 576px){
    .portfolio-hero{
    padding: 20vh 5vh 25vh 5vh;
    background-size: 100% 100%;
    }
    .hero-header-link-title{
    margin-top: 15vh;
    width: 100%;
    font-size: 40px;
    line-height: 50px;
    }

}

@media(min-width: 768px){
     .portfolio-hero{
    padding: 20vh 5vh 25vh 5vh;
    background-size: 100% 100%;
    }
    .hero-header-link-title{
    margin-top: 15vh;
    width: 70%;
    font-size: 60px;
    line-height: 80px;
    }


}

@media(min-width:992px){


}

@media(min-width:1200px) {
    .portfolio-hero{
    width: 100%;
    height: auto;
    color: #fff;
    background: linear-gradient(to right, rgba(28, 28, 28, 0.9), rgba(28, 28, 28, 0.9)), url(/src/assets/rec_1.png);
    padding-top: 19vh;
    padding-left: 15vh;
    background-size: cover;
}

.hero-header-link{
    text-decoration: none;
    color: #fff;
    font-size: 18px;
    line-height: 32px;
    font-weight: 400;
}

.hero-header-link-bold{
    font-weight: 600;
}

.hero-header-slash{
    margin-left: 2vh;
    margin-right: 2vh;
    display: inline-block;
}

.hero-header-link-title{
    margin-top: 12vh;
    width: 40%;
    font-size: 60px;
    line-height: 80px;
    font-weight: 400;
    text-align: left
}


}