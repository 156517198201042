.about-hero{
    width: 100%;
    height: 100vh;
    color: #fff;
    background:  url(/src/assets/rec_9.png) no-repeat;
    padding-top: 21vh;
    background-size: cover;
    padding-left: 15vh;
    background-position: center;
}

.about-header-link-title{
    margin-top: 12vh;
    width: 80%;
    font-size: 60px;
    line-height: 80px;
    font-weight: 400;
    text-align: left;
}