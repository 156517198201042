.blogdetails-section1-body{
    background-color: #f0feff;
    padding: 18vh 15vh 18vh 15vh;
}

.blogdetails-section1-sub{
    font-weight: 600;
font-size: 18px;
line-height: 32px;
}

.blogdetails-section1-title{
    font-weight: 600;
    margin-top: 3vh;
    margin-bottom: 1vh;
font-size: 30px;
line-height: 50px;
}
.blogdetails-section1-title-top{
    font-weight: 600;
font-size: 30px;
line-height: 50px;
margin-bottom: 5vh;
}

.blogdetails-author{
    font-weight: 400;
font-size: 16px;
line-height: 30px;
color: #acacac;
}

.blogdetails-section1-text{
    font-weight: 400;
font-size: 16px;
line-height: 30px;
text-align: justify !important;
}

.blogdetails-section1-img-place{
    position: relative;
  width: 100%;
  height: 60vh;
  overflow: hidden;
  margin-top: 3vh;
  margin-bottom: 3vh;
}

.tag-body-place{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

}

.tag-title{
    font-weight: 700;
font-size: 20px;
line-height: 32px;
color: #303030;
}

.tag-body{
    border: 1px solid #acacac;
    border-radius: 1vh;
    padding: 1vh;
    width: auto;
    margin-right: 1vh;
    margin-bottom: 1vh;
}

.blogdetails-section1-img{
    position: absolute;
    width: 100%;
  top: -9999px;
  left: -9999px;
  right: -9999px;
  bottom: -9999px;
  margin: auto;
}

@media(max-width: 400px) {
    .blogdetails-section1-body{
        padding: 10vh 5vh 10vh 5vh;
    }
}


@media(max-width: 576px){
    .blogdetails-section1-body{
        padding: 10vh 5vh 10vh 5vh;
    }


}

@media(min-width: 576px){
    .blogdetails-section1-body{
        padding: 10vh 5vh 10vh 5vh;
    }

}

@media(min-width: 768px){


}

@media(min-width:992px){


}

@media(min-width:1200px) {
    .blogdetails-section1-body{
        padding: 18vh 15vh 18vh 15vh;
    }
    
}
