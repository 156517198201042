.home-section3-body{
    height: auto;
    width: 100%;
    background-color: #f0f3ff;
    padding: 10vh 15vh 5vh 15vh;
}

.home-section3-sub{
    color: #b52023;
    font-size: 18px;
    font-weight: 600;
}

.home-section3-title{
    font-size: 30px;
    font-weight: 600;
    line-height: 50px;
    color: #303030;
    margin-top: 2vh;
    margin-bottom: 2vh;
}

.home-section3-caption{
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #303030;
    width: 60%;
}

.position{
    margin-top: 3vh;
}

.accordion-item{
    margin-top: 1vw;
    margin-bottom: 2vw;
}
.accordion-button {
    background-color: #f0f3ff !important;
    font-size: 27px;
    font-weight: 400;
    line-height: 50px;
    color: #303030;
}

.plus {
    display:inline-block;
    width:50px;
    height:50px;
    background:
      linear-gradient(#fff 0 0),
      linear-gradient(#fff 0 0),
      #000;
    background-position:center;
    background-size: 50% 3px,3px 50%; /*thickness = 2px, length = 50% (25px)*/
    background-repeat:no-repeat;
    transition: all 0.5s;
}

.alt {
    background:
      linear-gradient(#b52023 0 0),
      linear-gradient(#b52023 0 0);
    background-position:center;
    background-size: 50% 3px,3px 50%; /*thickness = 2px, length = 50% (25px)*/
    background-repeat:no-repeat;
  }

.accordion-button:focus {
    box-shadow: none;
  }
  
  .accordion-button:not(.collapsed) {
    color: #303030;
}

.accordion-button:not(.collapsed) .plus {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
     transform: rotate(45deg);
  }


  .accordion-body{
    background-color: #f0f3ff !important;
}

.accordion-button::after{
    display: none;
  }


.image-exp{
    max-width: 70%;
    margin: auto;
    display: flex;
}



.image-accor{
    max-width: 5vh;
    margin: 0.5vh;
  }

@media(max-width: 576px){
    .home-section3-body{
        padding: 5vh 4vh 5vh 4vh;
    }
    .home-section3-caption{
        line-height: 25px;
        width: 100%;
    }
    .accordion-button {
    font-size: 20px;
    line-height: 30px;
}

}

@media(min-width: 576px){
    .home-section3-body{
        padding: 5vh 4vh 5vh 4vh;
    }
    .home-section3-caption{
        line-height: 25px;
        width: 100%;
    }

}

@media(min-width: 768px){


}

@media(min-width:992px){
    .home-section3-body{
        padding: 5vh 4vh 5vh 4vh;
    }
    .home-section3-caption{
        line-height: 25px;
        width: 80%;
    }


}

@media(min-width:1200px) {
    .home-section3-body{
    height: auto;
    width: 100%;
    background-color: #f0f3ff;
    padding: 5vh 15vh 5vh 15vh;
}

.home-section3-sub{
    color: #b52023;
    font-size: 18px;
    font-weight: 600;
}

.home-section3-title{
    font-size: 30px;
    font-weight: 600;
    line-height: 50px;
    color: #303030;
    margin-top: 2vh;
    margin-bottom: 2vh;
}

.home-section3-caption{
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #303030;
    width: 60%;
}
.accordion-button {
    background-color: #f0f3ff !important;
    font-size: 27px;
    font-weight: 400;
    line-height: 50px;
    color: #303030;
}


}