.about-section3-body{
    background-color: #f9fbfe;
    padding: 18vh 15vh 18vh 15vh;
    color: #303030;
}

.about-section3-sub{
    font-weight: 600;
font-size: 18px;
line-height: 32px;
color: #c11c11;
}

.about-section3-title{
    font-weight: 600;
    font-size: 30px;
    line-height: 50px;
color: #303030;
}

.about-section3-caption{
    font-weight: 400;
font-size: 16px;
line-height: 30px;
color: #6e6e6e;
}

#process-carousel .item{
    text-align: left;
    padding: 4vh 6vh;
    margin-top: 5vh;
    border-radius: 2vh;
    margin-bottom: 0px;
    opacity: 0.5;
    min-height: 60vh;
    background-color: #f0f3ff;
    transform: scale3d(0.8, 0.8, 1);
    transition: all 0.3s ease-in-out;
}

#process-carousel .card-tab-header{
    display: flex;
    align-items: center;
}

#process-carousel .number-card{
    display: block;
    font-weight: 600;
font-size: 60px;
line-height: 90px;
color: #ccc;
margin-right: 0.5vh;
}

#process-carousel .owl-item.active.center .number-card{
    color: #D3797B;
}

#process-carousel .process-carousel-title{
    font-weight: 600;
    font-size: 27px;
    line-height: 50px;
}

#process-carousel .process-carousel-caption{
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
}
#process-carousel .process-carousel-list{
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
}

#process-carousel .owl-nav .owl-prev{
     position: absolute;
  border-radius: 3px;
  font-size: 80px;
  top: 50%;
  left: 10px;
  color: #303030;
  transition: all 0.8s;
}

#process-carousel .owl-nav .owl-next
{
    position: absolute;
  border-radius: 3px;
  font-size: 80px;
  top: 50%;
  right: 10px;
  color: #303030;
  transition: all 0.8s;
}





#process-carousel .owl-item.active.center .item {
    opacity: 1;
    transform: scale3d(1.0, 1.0, 1);
    background-color: #c11c11;
    color: #fff;
}

@media(max-width: 400px) {
    .about-section3-body{
    padding: 28vh 5vh 18vh 5vh;
    }

    #process-carousel .owl-nav .owl-prev{
     
    top: 40%;
    left: 10px;
    }

    #process-carousel .owl-nav .owl-next
    {
    top: 40%;
    right: 10px;
    }
}


@media(max-width: 576px){
    .about-section3-body{
    padding: 18vh 5vh 18vh 5vh;
    }

    #process-carousel .owl-nav .owl-prev{
     
    top: 40%;
    left: 10px;
    }

    #process-carousel .owl-nav .owl-next
    {
    top: 40%;
    right: 10px;
    }


}

@media(min-width: 576px){
    .about-section3-body{
    padding: 18vh 5vh 18vh 5vh;
    }

    #process-carousel .owl-nav .owl-prev{
     
    top: 40%;
    left: 10px;
    }

    #process-carousel .owl-nav .owl-next
    {
    top: 40%;
    right: 10px;
    }
    #process-carousel .item{
        min-height: 45vh;
    }

}

@media(min-width: 768px){
        .about-section3-body{
    padding: 18vh 5vh 18vh 5vh;
    }

    #process-carousel .owl-nav .owl-prev{
     
    top: 40%;
    left: 10px;
    }

    #process-carousel .owl-nav .owl-next
    {
    top: 40%;
    right: 10px;
    }
    #process-carousel .item{
        min-height: 35vh;
    }


}

@media(min-width:992px){
        .about-section3-body{
    padding: 18vh 5vh 18vh 5vh;
    }

    #process-carousel .owl-nav .owl-prev{
     
    top: 40%;
    left: 10px;
    }

    #process-carousel .owl-nav .owl-next
    {
    top: 40%;
    right: 10px;
    }
    #process-carousel .item{
        min-height: 40vh;
    }


}

@media(min-width:1200px) {
    .about-section3-body{
    padding: 18vh 15vh 18vh 15vh;
    }
    #process-carousel .owl-nav .owl-prev{
    border-radius: 3px;
    font-size: 80px;
    top: 50%;
    left: 10px;
    }

    #process-carousel .owl-nav .owl-next{
    border-radius: 3px;
    font-size: 80px;
    top: 50%;
    right: 10px;
    }
    #process-carousel .item{
        min-height: 55vh;
    }
}