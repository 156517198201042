.loader-body{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    color: #fff;
    width: 100%;
    height: 100vh;
}

.img-place-loader{
    display: flex;
    object-fit: contain;
}

.logic-img{
    width: auto;
    height: 10vh;
}

.o-img{
    width: auto;
    height: 10vh;
}

.de-img{
    width: auto;
    height: 10vh;
    margin-left: 0.5vh;
}

@media(max-width: 576px){
    .logic-img{
        width: auto;
        height: 5vh;
    }
    
    .o-img{
        width: auto;
        height: 5vh;
    }
    
    .de-img{
        width: auto;
        height: 5vh;
    }

}

@media(min-width: 576px){
    .logic-img{
        width: auto;
        height: 5vh;
    }
    
    .o-img{
        width: auto;
        height: 5vh;
    }
    
    .de-img{
        width: auto;
        height: 5vh;
    }

}

@media(min-width: 768px){
    


}

@media(min-width:992px){
    .logic-img{
        width: auto;
        height: 7vh;
    }
    
    .o-img{
        width: auto;
        height: 7vh;
    }
    
    .de-img{
        width: auto;
        height: 7vh;
    }



}

@media(min-width:1200px) {


}


