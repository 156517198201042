.contact-body{
    height: auto;
    width: 100%;
    background-color: #f9fbfe;
    background-size: cover;
    padding: 25vh 15vh 5vh 15vh;
    color: #303030;
}

.contact-title{
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    margin-top: 5vh;
}

.contact-exp{
    font-weight: 400;
font-size: 16px;
line-height: 30px;
color: #6E6E6E;
}

.contact-email{
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #6E6E6E;
    margin-top: 5vh;

}

.text-decoration-none-contact{
    text-decoration: none;
    color: #6E6E6E;
}

.contact-desc{
    color: #c11c11;
    font-weight: 600;
}

.contact-us{
    padding: 3vw 2.5vw 3vw 2.5vw;
    background-color: #f0f3fe;
    border-radius: 10px;
    text-align: left;}

.contact-us-title{
    font-weight: 600;
    font-size: 27px;
    line-height: 50px;
}

    .form-group{
        padding: 3vw 0 0 0;}
    .form-padding{
        padding:2vw 0 0 0;}
    .form-small{
        display: inline;
        border: 1px solid #f0f3fe;
        resize: none;}
    .form-small:hover{
        border: 1px solid #3f56ee;}

    .form-button{
        background-color: #c11c11;
        border:1px solid #c11c11;
        border-radius: 2vw;
        padding: 1vw 2vw 1vw 2vw;
        margin: 5vw 0 3vw 0;
        font-size: 16px;
    }
    .form-button:hover{
        background-color: #fff;
        color: #c11c11;
        border: 1px solid #c11c11;
}

 
@media(max-width: 576px){
    .contact-body{
        padding: 25vh 5vh 10vh 5vh;
    }
    .contact-us{
        margin-top: 5vh;
    }
    .form-small{
        margin-top: 2vh;
    }
    .form-button{
        padding: 2vh 3vh 2vh 3vh;
        border-radius: 2vh;
    }


}

@media(min-width: 576px){
    .contact-body{
        padding: 20vh 5vh 10vh 5vh;
    }
    .contact-us{
        margin-top: 5vh;
    }
    .form-small{
        margin-top: 2vh;
    }
    .form-button{
        padding: 2vh 3vh 2vh 3vh;
        border-radius: 2vh;
    }

}

@media(min-width: 768px){
    .contact-body{
        padding: 10vh 5vh 10vh 5vh;
    }
    .contact-us{
        margin-top: 5vh;
    }
    .form-small{
        margin-top: 2vh;
    }
    .form-button{
        padding: 2vh 3vh 2vh 3vh;
        border-radius: 2vh;
    }


}

@media(min-width:992px){


}

@media(min-width:1200px) {
    .contact-body{
        height: auto;
        width: 100%;
        background-size: cover;
        background-color: #f9fbfe;
        padding: 25vh 15vh 15vh 15vh;
        color: #303030;
    }
    
    .contact-title{
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        margin-top: 5vh;
    }
    
    .contact-exp{
        font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #6E6E6E;
    }
    
    .contact-email{
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        color: #6E6E6E;
        margin-top: 5vh;
    
    }
    
    .contact-desc{
        color: #c11c11;
        font-weight: 600;
    }
    
    .contact-us{
        padding: 3vw 2.5vw 3vw 2.5vw;
        background-color: #f0f3fe;
        border-radius: 10px;
        text-align: left;}
    
    .contact-us-title{
        font-weight: 600;
        font-size: 27px;
        line-height: 50px;
    }
    
        .form-group{
            padding: 3vw 0 0 0;}
        .form-padding{
            padding:2vw 0 0 0;}
        .form-small{
            display: inline;
            border: 1px solid #f0f3fe;}
        .form-small:hover{
            border: 1px solid #3f56ee;}
    
        .form-button{
            background-color: #c11c11;
            border:1px solid #c11c11;
            border-radius: 2vw;
            padding: 1vw 2vw 1vw 2vw;
            margin: 5vw 0 3vw 0;
            font-size: 16px;
        }
        .form-button:hover{
            background-color: #fff;
            color: #c11c11;
            border: 1px solid #c11c11;
    }


}