.blogdetails-hero{
    width: 100%;
    height: 100vh;
    color: #fff;
    background: linear-gradient(to right, rgba(28, 28, 28, 0.9), rgba(28, 28, 28, 0.9)), url(/src/assets/blog_1.png) no-repeat;
    padding-top: 21vh;
    background-size: 100% 100%;
    padding-left: 15vh;
}


.blogdetails-header-link-title{
    margin-top: 12vh;
    width: 80%;
    font-size: 60px;
    line-height: 80px;
    font-weight: 400;
    text-align: left;
}

.blogdetails-nav-place{
    margin-top: 2vw; 
    display: flex;
    width: 80%;
}
.blogdetails-button{
    font-size: 18px;
    line-height: 32px;
    font-weight: 400;
    color: #CCCCCC;
    cursor: pointer;
}

.blogdetails-button:hover{
    color: #fff;
    font-weight: 500;
}

.slash{
    font-size: 18px;
    margin-right: 3vw; 
    margin-left: 3vw;  
}

@media(max-width: 576px){
    .blogdetails-hero{
        height: auto;
        color: #fff;
        padding-top: 20vh;
        padding-left: 5vh;
        padding-bottom: 15vh;
    }
    .blogdetails-header-link-title{
        width: 80%;
        font-size: 50px;
        line-height: 70px;
        font-weight: 400;
    }


}

@media(min-width: 576px){
    .blogdetails-hero{
        padding-top: 20vh;
        padding-left: 5vh;
        padding-bottom: 15vh;
    }

}

@media(min-width: 768px){


}

@media(min-width:992px){


}

@media(min-width:1200px) {
    .blogdetails-hero{
        width: 100%;
        height: 100vh;
        color: #fff;
        background: linear-gradient(to right, rgba(28, 28, 28, 0.9), rgba(28, 28, 28, 0.9)), url(/src/assets/blog_1.png) no-repeat;
        padding-top: 21vh;
        background-size: 100% 100%;
        padding-left: 15vh;
    }
    
    
    .blogdetails-header-link-title{
        margin-top: 12vh;
        width: 80%;
        font-size: 60px;
        line-height: 80px;
        font-weight: 400;
        text-align: left;
    }
    
    .blogdetails-nav-place{
        margin-top: 2vw; 
        display: flex;
        width: 80%;
    }
    .blogdetails-button{
        font-size: 18px;
        line-height: 32px;
        font-weight: 400;
        color: #CCCCCC;
        cursor: pointer;
    }
    
    .blogdetails-button:hover{
        color: #fff;
        font-weight: 500;
    }
    
    .slash{
        font-size: 18px;
        margin-right: 3vw; 
        margin-left: 3vw;  
    }


}