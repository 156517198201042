@import url('https://fonts.googleapis.com/css?family=Poppins:wght@400,500,600,700&display=swap');


*{
    box-sizing: border-box;
   
}
html{
    scroll-behavior: smooth;
    
}
body{
    margin: 0;
    font-family: 'Poppins', sans-serif;
    padding: 0;
    width: 100%;
    height: 100%;
    background-color: #1c1c1c;
    overflow-x: hidden;
}

.loader-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #5a1214;
    color: #fff;
}

h1{
    font-size: 3vw;
    text-transfrom: uppercase;
    font-weight: 600;
}
h2{
    font-size: 1vw;
    text-transfrom: uppercase;
    font-weight: 600;
}

.absolute{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: black;
    z-index: 1; 
}

#progress{
    position:fixed;
    bottom: 20px;
    right: 10px;
    height: 70px;
    width: 70px;
    display: none;
    place-items: center;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    cursor: pointer;
    transition: 1;
    z-index: 10;
}

#progress-value{
    display: block;
    height: calc(100% - 15px);
    width: calc(100% - 15px);
    background-color: #c11c11;
    border-radius: 50%;
    display: grid;
    place-items: center;
    
}

.arrow-up{
    color: #fff;
    font-size: 3vh;
}

@media(max-width: 400px) {
    #progress{
        height: 50px;
        width: 50px;
    }
}


@media(max-width: 576px){


}

@media(min-width: 576px){

}

@media(min-width: 768px){


}

@media(min-width:992px){


}

@media(min-width:1200px) {
}

