.portfoliodetails-hero{
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(to right, rgba(28, 28, 28, 0.9), rgba(28, 28, 28, 0.9)), url(/src/assets/rec_2.png) no-repeat top fixed;
    background-size: 100%;
    background-position: 10% 40%;
    color: #fff;
    padding: 18vh 10vh 15vh 10vh;
}

.portfoliodetails-hero-title{
    margin-top: 20vh;
    width: 80%;
    font-size: 60px;
    line-height: 80px;
    font-weight: 400;
    text-align: left;
}

.portfoliodetails-hero-sub{
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
    width: 50%;
}

@media(max-width: 576px){
    .portfoliodetails-hero{
    background: linear-gradient(to right, rgba(28, 28, 28, 0.9), rgba(28, 28, 28, 0.9)), url(/src/assets/rec_2.png) no-repeat center fixed;
    padding: 18vh 5vh 10vh 5vh;
    background-size: 100% 100%;
    }
    .portfoliodetails-hero-title{
        margin-top: 10vh;
        width: 100%;
        font-size: 30px;
        line-height: 50px;
    }
    .portfoliodetails-hero-sub{
        margin-top: 2vh;
        width: 100%;
    }


}

@media(min-width: 576px){
    .portfoliodetails-hero{
        background: linear-gradient(to right, rgba(28, 28, 28, 0.9), rgba(28, 28, 28, 0.9)), url(/src/assets/rec_2.png) no-repeat center fixed;
        padding: 18vh 5vh 10vh 5vh;
        background-size: 100% 100%;
        }
        .portfoliodetails-hero-title{
            margin-top: 10vh;
            width: 100%;
            font-size: 40px;
            line-height: 60px;
        }
        .portfoliodetails-hero-sub{
            margin-top: 2vh;
            width: 100%;
        }

}

@media(min-width: 768px){
        .portfoliodetails-hero{
        background: linear-gradient(to right, rgba(28, 28, 28, 0.9), rgba(28, 28, 28, 0.9)), url(/src/assets/rec_2.png) no-repeat center fixed;
        padding: 18vh 5vh 10vh 5vh;
        background-size: 100% 100%;
        }
        .portfoliodetails-hero-title{
            margin-top: 10vh;
            width: 80%;
            font-size: 50px;
            line-height: 70px;
        }
        .portfoliodetails-hero-sub{
            margin-top: 2vh;
            width: 80%;
        }


}

@media(min-width:992px){
    .portfoliodetails-hero{
        background: linear-gradient(to right, rgba(28, 28, 28, 0.9), rgba(28, 28, 28, 0.9)), url(/src/assets/rec_2.png) no-repeat center fixed;
        padding: 20vh 5vh 10vh 5vh;
        background-size: 100% 100%;
        }
        .portfoliodetails-hero-title{
            margin-top: 12vh;
            width: 80%;
            font-size: 60px;
            line-height: 80px;
        }
        .portfoliodetails-hero-sub{
            margin-top: 2vh;
            width: 80%;
        }


}

@media(min-width:1200px) {
    .portfoliodetails-hero{
        width: 100%;
        min-height: 100vh;
        background: linear-gradient(to right, rgba(28, 28, 28, 0.9), rgba(28, 28, 28, 0.9)), url(/src/assets/rec_2.png) no-repeat top fixed;
        background-size: 100%;
        background-position: 10% 40%;
        color: #fff;
        padding: 18vh 10vh 15vh 10vh;
    }
    
    .portfoliodetails-hero-title{
        margin-top: 20vh;
        width: 80%;
        font-size: 60px;
        line-height: 80px;
        font-weight: 400;
        text-align: left;
    }
    
    .portfoliodetails-hero-sub{
        font-size: 16px;
        line-height: 30px;
        font-weight: 400;
        width: 50%;
    }
    


}

   