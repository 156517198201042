.portfoliolist-body{
    width: 100%;
    height: auto;
    background-color: #c11c11;
    color: #fff;
    padding: 15vh 10vh 15vh 10vh;
}

.portfoliolist-sub{
    font-size: 18px;
    font-weight: 600;
    line-height: 32px;
}

.portfoliolist-title{
    font-size: 30px;
    font-weight: 600;
    line-height: 50px;
}

.portfolio-row-place{
    padding-top: 4vh;
}

.align-right{
    margin-top: 20vh;
}

.parallax-inside-div{
    height: 80vh;
    width: 60vh;
}






.portfoliolist-image-place{
    width: 100%;
    object-position: 5px 10%;
}

.portfoliolist-image{
    max-width: 80%;
    transition: all 0.8s;
}

.portfoliolist-image:hover{
    scale: 0.95;
}




.portfolioimage-sub{
    display: inline-block;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    margin-top: 2vh; padding-right: 2vh;
    width: 80%;
}

.portfolioimage-title{
    font-size: 30px;
    line-height: 50px;
    font-weight: 600;
    width: 80%;
}

.portfolioimage-exp{
    font-size: 16px;
    width: 80%;
    font-weight: 400;
    line-height: 30px;
}

hr.portfolioline{
    border: 1px solid #fff;
    opacity: 1;
    width: 15%;
    display: inline-block;
    margin: auto;
}




@media(max-width: 576px){
    .portfoliolist-body{
        height: auto;
        padding: 8vh 5vh 8vh 5vh;
    }
    .portfoliolist-title{
        font-size: 25px;
        line-height: 40px;
    }
    .portfolioimage-title{
        font-size: 20px;
        line-height: 40px;
        width: 50%;
    }
    .portfolioimage-exp{
        width: 60%;
        text-align: justify;
    }
    .align-right{
        margin-top: 4vh;
    }

    .portfoliolist-image{
        max-width: 60%;
        transition: all 0.8s;
    }
    .portfolioimage-sub{
        width: 60%;
    }

    .parallax-inside-div{
    height: 50vh;
    width: 60vh;
    }





}

@media(min-width: 576px){
    .portfoliolist-body{
        height: auto;
        padding: 8vh 5vh 8vh 5vh;
    }
    .portfoliolist-title{
        font-size: 35px;
        line-height: 45px;
    }
    .portfolioimage-title{
        font-size: 20px;
        line-height: 35px;
        width: 100%;
    }
    .portfolioimage-exp{
        margin-top: 1vh;
        font-size: 16px;
        width: 80%;
        line-height: 25px;
    }
    .align-right{
        margin-top: 4vh;
    }

    .portfoliolist-image-place{
        width: 100%;
    }
    .parallax-inside-div{
    height: 50vh;
    width: 60vh;
    }

}

@media(min-width: 768px){
    .portfolioimage-sub{
        display: inline-block;
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
        margin-top: 2vh; padding-right: 2vh;
        width: 80%;
    }
    
    .portfolioimage-title{
        font-size: 30px;
        line-height: 50px;
        font-weight: 600;
        width: 80%;
    }
    
    .portfolioimage-exp{
        font-size: 16px;
        width: 80%;
        font-weight: 400;
        line-height: 30px;
    }

    .align-right{
        margin-top: 10vh;
    }
    .parallax-inside-div{
        height: 40vh;
        width: 60vh;
        }


}

@media(min-width:992px){


}

@media(min-width:1200px) {
    .portfoliolist-body{
        width: 100%;
        height: auto;
        background-color: #c11c11;
        color: #fff;
        padding: 15vh 10vh 15vh 10vh;
    }
    
    .portfoliolist-sub{
        font-size: 18px;
        font-weight: 600;
        line-height: 32px;
    }
    
    .portfoliolist-title{
        font-size: 30px;
        font-weight: 600;
        line-height: 50px;
    }

    .portfolioimage-sub{
        display: inline-block;
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
        margin-top: 2vh; padding-right: 2vh;
        width: 80%;
    }
    
    .portfolioimage-title{
        font-size: 30px;
        line-height: 50px;
        font-weight: 600;
        width: 80%;
    }
    
    .portfolioimage-exp{
        font-size: 16px;
        width: 80%;
        font-weight: 400;
        line-height: 30px;
    }
    .align-right{
        margin-top: 20vh;
    }
    .parallax-inside-div{
        height: 70vh;
        width: 60vh;
    }


}