.portfoliodetails-section4-body{
    height: 100vh;
    width: 100%;
    background-color: #f0f3ff;
    padding: 20vh 15vh 5vh 15vh;
    color: #303030;
}

.portfoliodetails-section4-sub{
    color: #c11c11;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
}

.portfoliodetails-section4-title{
    font-size: 30px;
    font-weight: 600;
    line-height: 50px;
    width: 60%;
}

.portfoliodetails-section4-text{
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    width: 50%;
    text-align: justify;
}

.scope-container{
    margin-top: 5vh;
}

.image-scope{
    display: block;
}

.scope-title{
    font-size: 20px;
    line-height: 32px;
    font-weight: 600;
}

.scope-image{
    max-width: 100%;
    opacity: 0.1;
}


@media(max-width: 576px){
    .portfoliodetails-section4-body{
        height: auto;
        padding: 8vh 5vh 8vh 5vh;
    }

    .portfoliodetails-section4-title{
        width: 100%;
    }
    .portfoliodetails-section4-text{
        text-align: left;
        width: 100%;
    }

    .scope-image{
        max-width: 80%;
    }

    .scope-title{
        font-size: 15px;
    }


}

@media(min-width: 576px){
    .portfoliodetails-section4-body{
        height: auto;
        padding: 8vh 5vh 8vh 5vh;
    }

    .portfoliodetails-section4-title{
        width: 100%;
    }
    .portfoliodetails-section4-text{
        text-align: left;
        width: 100%;
    }

    .scope-image{
        max-width: 80%;
    }

    .scope-title{
        font-size: 20px;
    }

}

@media(min-width: 768px){
    .portfoliodetails-section4-body{
        height: auto;
        padding: 8vh 5vh 8vh 5vh;
    }

    .portfoliodetails-section4-title{
        width: 100%;
    }
    .portfoliodetails-section4-text{
        text-align: left;
        width: 100%;
    }

    .scope-image{
        max-width: 80%;
    }

    .scope-title{
        font-size: 20px;
    }


}

@media(min-width:992px){


}

@media(min-width:1200px) {
    .portfoliodetails-section4-body{
        height: 100vh;
        width: 100%;
        background-color: #f0f3ff;
        padding: 20vh 15vh 5vh 15vh;
        color: #303030;
    }
    
    .portfoliodetails-section4-sub{
        color: #c11c11;
        font-weight: 600;
        font-size: 18px;
        line-height: 32px;
    }
    
    .portfoliodetails-section4-title{
        font-size: 30px;
        font-weight: 600;
        line-height: 50px;
        width: 60%;
    }
    
    .portfoliodetails-section4-text{
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        width: 50%;
        text-align: justify;
    }
    
    .scope-container{
        margin-top: 5vh;
    }
    
    .image-scope{
        display: block;
    }
    
    .scope-title{
        font-size: 20px;
        line-height: 32px;
        font-weight: 600;
    }
    
    .scope-image{
        max-width: 100%;
    }


}