.process-section2-body{
    background-color: #1c1c1c;
    padding: 17vh 15vh 17vh 15vh;
    color: #fff;
}

.process-img{
    width: 100%;
    display: block;
}

.method-title{
    font-weight: 600;
font-size: 30px;
line-height: 50px;
}

.method-text-place{
    width: 100%;
    display: flex;
    padding-top: 5vh;
    padding-bottom: 5vh;
    flex-direction: column; 
    justify-content: center;
}

.method-text{
    font-weight: 400;
font-size: 16px;
line-height: 30px;
text-align: justify;
}

.image-place-process{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.tab-value1{
    width: 90%;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #fff;
}
.value-icon1{
    color: #c11c11;;
    font-size: 30px;
    display: block;
    margin-right: 1vh;
}

.tab-value1-place{
    display: flex;
    margin-top: 2vh;
}

.carousel-indicators{
    bottom: -10vh !important;
}

.carousel-indicators button{
    border-radius: 50% !important;
    height: 2vh !important;
    width: 2vh !important;
}


@media(max-width: 400px){
    .process-section2-body{
    padding: 10vh 5vh 10vh 5vh;
    }
    .image-place-process{
    height: auto;
    }

}


@media(max-width: 576px){
    .process-section2-body{
    padding: 10vh 5vh 10vh 5vh;
    }   
    .image-place-process{
    height: auto;
    }

}

@media(min-width: 576px){
    .process-section2-body{
    padding: 10vh 5vh 10vh 5vh;
    } 
    .image-place-process{
    height: auto;
    }

}

@media(min-width: 768px){
    .image-place-process{
    height: 40vh;
    }


}

@media(min-width:992px){


}

@media(min-width:1200px) {
    .process-section2-body{
    padding: 17vh 15vh 17vh 15vh;
    }
    .image-place-process{
    height: 100vh;
    }
}
