.client-body{
    background-color: #c11c11;
    height: auto;
    padding: 15vh;
    text-align: center;
    color: #fff;
}

.client-title{
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: #fff;
}

.client-list{
    margin-top: 5vw;
    align-items: center;
    justify-content: center;
}

.image{
    max-width: 25vh;
    margin: 4vh;
}


.client-space{
    justify-content: space-between;
    text-align: center;
    margin: auto;
    margin-top: 5vw;
}


#grid-client {
    display: grid;
    grid-auto-rows: auto;
    grid-auto-columns: auto;
    grid-template-areas: "a a a";
}








@media(max-width: 576px){
    .client-body{
        padding: 5vh 2vh 5vh 2vh;
    }
    .image{
        max-width: 13vh;
    }
    .client-space{
        margin-top: 2vw;
    }
    #grid-client {
        grid-template-areas: "a a";
    }



}

@media(min-width: 576px){
    .client-body{
        padding: 5vh;
    }
    .image{
        max-width: 10vh;
    }
    .client-space{
        margin-top: 2vw;
    }
    #grid-client {
        grid-template-areas: "a a";
    }
}

@media(min-width: 768px){
    .client-body{
        padding: 5vh;
    }
    .image{
        max-width: 15vh;
    }
    .client-space{
        margin-top: 2vw;
    }


}

@media(min-width:992px){
    .client-body{
        padding: 5vh;
    }
    .image{
        max-width: 15vh;
    }
    .client-space{
        margin-top: 2vw;
    }
    #grid-client {
        grid-template-areas: "a a a";
    }


}

@media(min-width:1200px) {
    .client-body{
        background-color: #c11c11;
        height: auto;
        padding: 15vh;
        text-align: center;
        color: #fff;
    }
    
    .client-title{
        font-weight: 600;
        font-size: 18px;
        line-height: 32px;
        color: #fff;
    }
    
    .client-list{
        margin-top: 5vw;
        align-items: center;
        justify-content: center;
    }
    
    .image{
        max-width: 20vh;
        margin: 4vh;
       
    }
    
    .client-space{
        justify-content: space-between;
        text-align: center;
        margin: auto;
        margin-top: 5vw;
    }


}
