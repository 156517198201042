.about-section1-body{
    background-color: #f9fbfe;
    padding: 18vh 15vh 18vh 15vh;
}

.about-section1-sub{
    font-weight: 600;
font-size: 18px;
line-height: 32px;
color: #c11c11;
}

.about-section1-title{
    font-weight: 600;
font-size: 30px;
line-height: 50px;
color: #303030;
}

.about-section1-text{
    margin-top: 5vh;
    font-weight: 400;
font-size: 16px;
line-height: 30px;
color: #303030;
}