.services-section1-body{
    height: auto;
    width: 100%;
    background-color: #f9fbfe;
    padding: 25vh 15vh 5vh 15vh;
    color: #303030;
}

.services-section1-sub{
    color: #c11c11;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
}

.services-section1-title{
    font-size: 30px;
    font-weight: 600;
    line-height: 50px;
    width: 60%;
    margin-bottom: 6vh;
}

.services-accordion .accordion-button{
    background-color: #f9fbfe !important;
    font-size: 27px;
    font-weight: 400;
    line-height: 50px;
    color: #303030;
    display: flex;
}


.services-accordion .accordion-title{
    width: 70%;
  }

.services-accordion .plus{
    justify-content: end;
}

.services-accordion .accordion-body{
    background-color: #fbf9fe !important;
}

.services-accordion accordion-button::after{
    display: none;
  }

  .services-accordion  .plus {
    display:inline-block;
    width:50px;
    height:50px;
    background:
      linear-gradient(#fff 0 0),
      linear-gradient(#fff 0 0),
      #000;
    background-position:center;
    background-size: 50% 3px,3px 50%; /*thickness = 2px, length = 50% (25px)*/
    background-repeat:no-repeat;
    transition: all 0.5s;
  }
  
  .services-accordion .alt {
    background:
      linear-gradient(#b52023 0 0),
      linear-gradient(#b52023 0 0);
    background-position:center;
    background-size: 50% 3px,3px 50%; /*thickness = 2px, length = 50% (25px)*/
    background-repeat:no-repeat;
  }

  .services-accordion .accordion-button:focus {
    box-shadow: none;
  }
  
  .services-accordion .accordion-button:not(.collapsed) {
    color: #303030;
  }


  .services-accordion .accordion-button:not(.collapsed) .plus {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
     transform: rotate(45deg);
  }

  .services-accordion .accordion-button::after{
    display: none;
  }

  .services-accordion .accordion-text{
    text-align: justify;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: #303030;
  }

  .tab-value2{
    width: 90%;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: #303030;
}
.value-icon2{
    color: #c11c11;;
    font-size: 30px;
    display: block;
    margin-right: 1vh;
}

.tab-value2-place{
    display: flex;
    margin-top: 1vh;
}

  .number-margin{
        margin-right: 5vh;
  }

  .services-image-section1{
    max-width: 100%
  }

  .services-parallax{
    height: 100vh;
  }

  


  @media(max-width: 576px){
    .services-section1-body{
        height: auto;
        padding: 18vh 2vh 5vh 2vh;
    }
    .services-section1-title{
        width: 100%;
        margin-bottom: 2vh;
    }
    .number-margin{
        margin-right: 1vh;
    }
    .services-accordion .accordion-button{
        font-size: 20px;
        line-height: 30px;
    }


  }
  
  @media(min-width: 576px){
    .services-section1-body{
        height: auto;
        padding: 10vh 5vh 5vh 5vh;
    }
    .services-section1-title{
        width: 100%;
        margin-bottom: 2vh;
    }
    .number-margin{
        margin-right: 1vh;
    }
    .services-accordion .accordion-button{
        font-size: 20px;
        line-height: 30px;
    }
  
  }
  
  @media(min-width: 768px){
  
  }
  
  @media(min-width:992px){
  
  }
  
  @media(min-width:1200px) {
    .services-section1-body{
        height: auto;
        width: 100%;
        padding: 25vh 15vh 5vh 15vh;
        color: #303030;
    }
    
    .services-section1-sub{
        color: #c11c11;
        font-weight: 600;
        font-size: 18px;
        line-height: 32px;
    }
    
    .services-section1-title{
        font-size: 30px;
        font-weight: 600;
        line-height: 50px;
        width: 60%;
        margin-bottom: 6vh;
    }
    
    .services-accordion .accordion-button{
        font-size: 27px;
        font-weight: 400;
        line-height: 50px;
        color: #303030;
        display: flex;
    }
    
    .services-accordion .accordion-title{
        width: 70%;
      }
    
    .services-accordion .plus{
        justify-content: end;
    }
    
    .services-accordion .accordion-body{
        background-color: #fbf9fe !important;
    }
    
    .services-accordion accordion-button::after{
        display: none;
      }
    
      .services-accordion  .plus {
        display:inline-block;
    
        width:50px;
        height:50px;
        background:
          linear-gradient(#fff 0 0),
          linear-gradient(#fff 0 0),
          #000;
        background-position:center;
        background-size: 50% 3px,3px 50%; /*thickness = 2px, length = 50% (25px)*/
        background-repeat:no-repeat;
        transition: all 0.5s;
      }
      
      .services-accordion .alt {
        background:
          linear-gradient(#b52023 0 0),
          linear-gradient(#b52023 0 0);
        background-position:center;
        background-size: 50% 3px,3px 50%; /*thickness = 2px, length = 50% (25px)*/
        background-repeat:no-repeat;
      }
    
      .services-accordion .accordion-button:focus {
        box-shadow: none;
      }
      
      .services-accordion .accordion-button:not(.collapsed) {
        color: #303030;
      }
    
    
      .services-accordion .accordion-button:not(.collapsed) .plus {
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
         transform: rotate(45deg);
      }
    
      .services-accordion .accordion-button::after{
        display: none;
      }
    
      .services-accordion .accordion-text{
        text-align: justify;
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
        color: #303030;
    
      }
    
      .number-margin{
            margin-right: 5vh;
      }
  
  
  
}
  