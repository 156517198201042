.services-hero{
    width: 100%;
    height: 45vh;
    background: linear-gradient(to right, rgba(28, 28, 28, 0.9), rgba(28, 28, 28, 0.9)), url(/src/assets/rec_1.png) no-repeat top fixed;
    background-size: cover;
    background-position: 10% 40%;
    color: #fff;
    padding: 18vh 10vh 15vh 10vh;
}


.header-links{
    text-decoration: none;
    color: #fff;
    font-weight: 400;
    margin-right: 3vh;
    margin-left: 3vh;
    font-size: 18px;
}

.services-overlay{
    width: 110vh;
    height: 21vh;
    background-color: #c11c11;
    position: absolute;
    margin: auto;
    top: 35vh;
    left: 0;
    right: 0;
    border-radius: 2vw;
    justify-content: center;
    text-align: center;
}

.services-overlay-text{
    color: #fff;
    font-size: 30px;
    font-weight: 600px;
    margin-top: 5vh;
}

.services-overlay-sub{
    color: #fff;
    font-size: 20px;
    line-height: 32px;
    padding-top: 1vh;
    font-weight: 400;
}

@media(max-width: 576px){
    .services-overlay{
    width: 100%;
    height: auto;
    top: 35vh;
    }
    .services-overlay-text{
        font-size: 23px;
        margin-top: 3vh;
    }
    .services-overlay-sub{
    font-size: 17px;
    line-height: 32px;
    padding-top: 1vh;
    font-weight: 400;
    margin-bottom: 3vh;
    }




}

@media(min-width: 576px){
        .services-overlay{
    width: 100%;
    height: auto;
    top: 35vh;
    }
    .services-overlay-text{
        font-size: 25px;
        margin-top: 4vh;
    }
    .services-overlay-sub{
    font-size: 17px;
    line-height: 32px;
    padding-top: 1vh;
    font-weight: 400;
    margin-bottom: 4vh;
    }

}

@media(min-width: 768px){
            .services-overlay{
    width: 100%;
    height: auto;
    top: 35vh;
    }
    .services-overlay-text{
        font-size: 28px;
        margin-top: 4vh;
    }
    .services-overlay-sub{
    font-size: 17px;
    line-height: 32px;
    padding-top: 1vh;
    font-weight: 400;
    margin-bottom: 4vh;
    }


}

@media(min-width:992px){



}

@media(min-width:1200px) {

    .services-hero{
    width: 100%;
    height: auto;
    background: linear-gradient(to right, rgba(28, 28, 28, 0.9), rgba(28, 28, 28, 0.9)), url(/src/assets/rec_1.png) no-repeat top fixed;
    background-size: cover;
    background-position: 10% 40%;
    color: #fff;
    padding: 18vh 10vh 25vh 10vh;
}


.header-links{
    text-decoration: none;
    color: #fff;
    font-weight: 400;
    margin-right: 3vh;
    margin-left: 3vh;
    font-size: 18px;
}

.services-overlay{
    width: 110vh;
    height: auto;
    background-color: #c11c11;
    position: absolute;
    margin: auto;
    top: 35vh;
    left: 0;
    right: 0;
    border-radius: 2vw;
    justify-content: center;
    text-align: center;
}

.services-overlay-text{
    color: #fff;
    font-size: 30px;
    font-weight: 600px;
    margin-top: 5vh;
}

.services-overlay-sub{
    color: #fff;
    font-size: 20px;
    line-height: 32px;
    padding-top: 1vh;
    font-weight: 400;
    margin-bottom: 5vh;
}


}