.career-hero{
    width: 100%;
    height: 45vh;
    background: linear-gradient(to right, rgba(28, 28, 28, 0.9), rgba(28, 28, 28, 0.9)), url(/src/assets/rec_1.png) no-repeat top fixed;
    background-size: cover;
    background-position: 10% 40%;
    color: #fff;
    padding: 18vh 10vh 15vh 10vh;
}

.career-body-sub{
    font-weight: 600;
font-size: 18px;
line-height: 32px; color: #c11c11;
}

.career-body-title{
    font-weight: 600;
font-size: 30px;
line-height: 50px;
}

.career-body-caption{
    font-weight: 400;
font-size: 16px;
line-height: 30px;
color: #6e6e6e;
margin-bottom: 5vh;
}

.career-body{
    padding: 20vh 15vh 18vh 15vh;
    background-color: #fff; 
    text-align: center;
}

#grid-career{
    display: grid;
    grid-auto-rows: auto;
    grid-auto-columns: auto;
    grid-template-areas: "a a a";
}

.cards-career{
    margin-bottom: 6vh;
    border-radius:0.6vw;
    padding: 6vh 5vh 6vh 5vh ;
    background-color:#ffffff;
    border: 1px #f9fbfe;
    text-align:left;
    width: 95%;
    height: auto;
    transition: all 0.8s;
    box-shadow: 1px 4px 15px 1px rgba(217,221,252,0.56);
    -webkit-box-shadow: 1px 4px 15px 1px rgba(217,221,252,0.56);
    -moz-box-shadow: 1px 4px 15px 1px rgba(217,221,252,0.56);
}


.cards-career:hover{
    border: 1px solid #c11c11;
}

.cards-career:hover .card-career-title{
    color: #303030;
}

.cards-career:hover .card-career-exp{
    color: #ACACAC;
}

.cards-career:hover .cards-career-level{
    color: #fff;
}

.card-career-title{
    font-weight: 600;
    display: inline-block;
    font-size: 20px;
    line-height: 32px;
    color: #303030;
}

.cards-career-level{
    font-weight: 600;
    font-size: 14px;
    line-height: 28px;
    color: #FFFFFF;
    border-radius: 50%;
    height: 5vh;
    width: 5vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card-career-exp{
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: #ACACAC;
    height: 40vh;
    overflow-y:scroll;
    overflow-x:hidden;
}

.career-button{
    background-color: #c11c11 !important;
    border: none;
    border-radius: 1vw;
    color:#fff;
    width: 100%;
    padding: 1vh 0 1vh 0 ;
    margin-top: 1vw;
}

.cards-career:hover .career-button{
    border: 2px solid #fff;
}

.career-button:hover{
    color: #c11c11;
    background-color: #ffffff !important;
}

hr.career-line{
    border-top: 2px solid #f0f3fd;
}

.section-btn-load-more{
    background-color: #c11c11;
    border: none;
    padding: 2vh 4vh 2vh 4vh !important;
}

.rows-career{
    width: 100%;
}

@media(max-width: 576px){
    .career-card-body{
        padding: 10vh 5vh 14vh 5vh;
        background-color: #f9fbfe;
    }
    #grid-career{
        grid-template-areas: "a";
    }

    .career-body{
        padding: 20vh 5vh 18vh 5vh;
    }




}

@media(min-width: 576px){
    .career-card-body{
    padding: 10vh 5vh 14vh 5vh;
    background-color: #f9fbfe;
    }

    .career-body{
        padding: 20vh 5vh 18vh 9vh;
    }

    #grid-career{
        grid-template-areas: "a";
    }

}

@media(min-width: 768px){
    .career-body{
        padding: 15vh 5vh 18vh 9vh;
    }


}

@media(min-width:992px){
    .career-body{
        padding: 15vh 5vh 18vh 5vh;
    }
    #grid-career{
        grid-template-areas: "a a";
    }


}

@media(min-width:1200px) {
    #grid-career{
        grid-template-areas: "a a a";
    }
    .career-body{
        padding: 20vh 15vh 18vh 15vh;
    }


}
