.services-section2-body{
    height: auto;
    width: 100%;
    background-color: #1c1c1c;
    padding: 15vh 15vh 15vh 15vh;
    color: #fff;
}

.services-section2-imageplace{
    object-fit: contain;
    width: 90%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.services-section2-imagesection{
    max-width: 100%;
}

.services-section2-title{
    font-size: 30px;
    font-weight: 600;
    line-height: 50px;
    margin-top: 5vh;
    margin-bottom: 4vh;
}

.services-section2-text{
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 4vw;
    line-height: 30px;
}

.bold-red{
    font-weight: 600;
    color: #c11c11;
}



@media(max-width: 576px){
    .services-section2-body{
        height: auto;
        padding: 12vh 5vh 12vh 5vh;
    }


}

@media(min-width: 576px){
    .services-section2-body{
        height: auto;
        padding: 12vh 5vh 12vh 5vh;
    }
    .services-section2-text{
        margin-bottom: 4vh;
    }

}

@media(min-width: 768px){
    .services-section2-title{
        margin-top: 3vh;
        margin-bottom: 3vh;
    }


}

@media(min-width:992px){


}

@media(min-width:1200px) {
    .services-section2-body{
        height: auto;
        width: 100%;
        background-color: #1c1c1c;
        padding: 15vh 15vh 15vh 15vh;
        color: #fff;
    }
    
    .services-section2-imageplace{
        object-fit: contain;
        width: 90%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .services-section2-imagesection{
        max-width: 100%;
    }
    
    .services-section2-title{
        font-size: 30px;
        font-weight: 600;
        line-height: 50px;
        margin-top: 5vh;
        margin-bottom: 4vh;
    }
    
    .services-section2-text{
        font-weight: 400;
        font-size: 16px;
        margin-bottom: 4vw;
        line-height: 30px;
    }


}
