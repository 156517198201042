.carousel-body{
    position: absolute;
    width: 90%;
    top: 0;
    margin-top: 30vh;
    margin-left: 20vh;
    left: 0;
    color: #fff;
}
.carousel-number{
    font-size: 18px;
    font-weight: 600;
}

.carousel-title{
    font-size: 60px;
    font-weight: 400;
    line-height: 80px;
    padding-top: 1vw;
}

.carousel-text{
    font-size: 16px;
    width: 60%;
    line-height: 30px;
    padding-top: 1vw;
}

.carousel-place{
    margin-top: 2vw; 
    display: flex;
}
.carousel-button{
    font-size: 18px;
    line-height: 32px;
    font-weight: 400;
    cursor: pointer;
}

.carousel-button:hover{
    font-size: 20px;
}

.slash{
    font-size: 18px;
    margin-right: 3vw; 
    margin-left: 3vw;  
}

.carousel-control-prev-icon, 
.carousel-control-next-icon{
    display: none
}



@media(max-width: 576px){
    .carousel-body{
        margin-top:22vh;
        margin-left:5vh;
        width: 80%;
        margin-right:4vh;
    }
    .carousel-title{
        font-size: 25px;
        line-height: 40px;
        padding-top: 0.5vw;
    }

    .carousel-text{
        width: 90%;
        line-height: 25px;
        padding-top: 4vw;
    }

}

@media(min-width: 576px){
    .carousel-body{
        margin-top:25vh;
        margin-left:6vh;
        width: 80%;
        margin-right:4vh;
    }
    .carousel-title{
        font-size: 35px;
        line-height: 50px;
        padding-top: 0.5vw;
    }
    .carousel-text{
        width: 90%;
        line-height: 30px;
        padding-top: 3vw;
    }
}

@media(min-width: 768px){
    .carousel-body{
        margin-top: 25vh;
        margin-left: 7vh;
        margin-right: 8vh;
    }
    .carousel-title{
        font-size: 50px;
        line-height: 60px;
        padding-top: 0.5vw;
    }

}

@media(min-width:992px){
    .carousel-body{
        margin-top: 25vh;
        margin-left: 8vh;
        margin-right: 8vh;
    }
    .carousel-title{
        font-size: 55px;
        line-height: 60px;
        padding-top: 0.5vw;
    }

}

@media(min-width:1200px) {

    .carousel-body{
        margin-top: 30vh;
        margin-left: 15vh;
        max-width: 75%;
        margin-right: 20vh;
    }

    .carousel-title{
        font-size: 60px;
        font-weight: 400;
        line-height: 80px;
        padding-top: 0.5vw;
    }
    .carousel-text{
        font-size: 16px;
        width: 70%;
        line-height: 30px;
        padding-top: 1vw;
    }

}