.home-section2{
    min-height: 80vh;
    width: 100%;
    background-color: #f9fbfe;;
    padding: 11vh 18vh 8vh 18vh;
}

.home-section2-sub{
    color: #b52023;
    font-size: 18px;
    font-weight: 600;
}

.home-section2-title{
    font-size: 30px;
    font-weight: 600;
    padding-top: 1vw;
    line-height: 50px;
}

.home-section2-tab {
    height: auto;
    margin: 1.5rem auto 1.5rem;
    color: #E8F0F2;
}

.grey-base{
    color: #303030 !important;
}

ul.homesection2 {
    display: flex;
    color: #6e6e6e;
    background-color: #fff;
    font-size: 18px;
    line-height: 32px;
    font-weight: 500;
    transition: all 2s;
    justify-content: space-between;
    border-radius: 0.5rem;
    padding-left: 0px;
}

ul.homesection2 li {
    padding: 2.5vw 0 2.5vw 0;
    list-style: none;
    text-align: center;
    cursor: pointer;
    border-bottom: 5px solid transparent;
}

  ul.homesection2 li:first-child {
    border-bottom-left-radius: 1rem;
    border-top-left-radius: 1rem;
}
   
  ul.homesection2 li:last-child {
    border-bottom-right-radius: 1rem;
    border-top-right-radius: 1rem;
}

ul.homesection2 li:hover {
    border-bottom: 5px solid #c11137;
    color: #C11137;
    font-weight: 600;

}

ul.homesection2 li:hover .title-icon {
    color: #C11137;
}

ul.homesection2 li.active .title-icon {
    color: #c11137;
}




ul.homesection2 li.active {
    font-weight: 600;
    color: #c11137;
    border-bottom: 5px solid #c11137;
}


  .title-icon{
    font-size: 35px;
    float: left;
    margin-right: 2vh;
    color: #F0F3FF;
  }

.home-section2-tab-title{
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    width: 70%;
 
}

.home-section2-tab-nav{
    margin: auto;
    display: flex;
    align-items: center;
    width: 60%;
}

.tab-content{
    padding-top: 2vh;
    color: #303030;
}

.tab-align{
    margin: auto;
    text-align: justify !important;
    float: left;
    width: 60%;
    margin-right: 0;
    margin-left: 0;
}

.tab-image-placement{
    display: flex;
    justify-content: center;
    height: 100%;
}

.image-col{
    display: flex;
    justify-content: center;
    align-items: center;

}
  .tab-img{
    max-width: 100%;
    float: right;
    display: inline-flex;
}
.tab-content-title{
    font-weight: 600;
    font-size: 30px;
    text-align: left;
    padding-bottom: 1vw;
    line-height: 60px;
}
.tab-content-text{
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 4vw;
    line-height: 30px;
}
.tab-value{
    display: inline-flex;
    width: 90%;
    
}
.value-icon{
    color: #b52023;
    font-size: 30px;
    display: inline-flex;}

.tab-value-place{
    margin-top: 1vh;
    display: flex;
   align-items: center;
}

.bold-red{
    font-weight: 700;
    color: #c11c11;

}



  .row{
    margin: auto !important;
  }


@media(max-width: 576px){
    .home-section2{
        padding: 5vh 6vh 5vh 6vh;
        height: auto;
    }
    .home-section2-sub{
        font-size: 18px;
        font-weight: 600;
    }
    
    .home-section2-title{
        font-size: 25px;
        line-height: 35px;
    }
    ul.homesection2 {
        display: block;
        font-size: 18px;
    }
    ul.homesection2 li {
        padding: 1.5vw 0 1.5vw 0;
        border-bottom: 5px solid transparent;
    }
    .home-section2-tab-nav{
        width: 80%;
    }
    .home-section2-tab-title{
        width: 80%;
        line-height: 25px;
    }
    ul.homesection2 li:first-child {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }
       
      ul.homesection2 li:last-child {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
    }
    .tab-content{
        padding-top: 0;
    }
    .tab-content-title{
        font-size: 30px;
        text-align: left;
        padding-bottom: 0;
        line-height: 50px;
    }
    .tab-align{
        float: left;
        width: 100%;
    }
    .title-icon{
        font-size: 15px;
        float: left;
        margin-right: 2vh;
      }

      .tab-image-placement{
        margin-top: 3vh;
        height: 80%;
    }
      .tab-img{
        max-width: 100%;
        float: right;
        display: inline-flex;
    }



}

@media(min-width: 576px){
    .home-section2{
        padding: 8vh 6vh 8vh 6vh;
        height: auto;
    }
    .home-section2-sub{
        font-size: 18px;
        font-weight: 600;
    }
    
    .home-section2-title{
        font-size: 25px;
        line-height: 35px;
    }
    ul.homesection2 {
        display: block;
        font-size: 18px;
    }
    ul.homesection2 li {
        padding: 2vw 0 2vw 0;
        border-bottom: 5px solid transparent;
    }
    .home-section2-tab-nav{
        width: 80%;
    }
    .home-section2-tab-title{
        width: 80%;
        line-height: 25px;
    }
    ul.homesection2 li:first-child {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }
       
      ul.homesection2 li:last-child {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
    }
    .tab-align{
        margin: auto;
        text-align: justify !important;
        float: left;
        width: 100%;
        margin-right: 0;
        margin-left: 0;
    }
    .tab-content{
        padding-top: 0;
    }
    .tab-content-title{
        font-size: 30px;
        text-align: left;
        padding-bottom: 0;
        line-height: 50px;
    }
    .tab-align{
        float: left;
        width: 100%;
    }
    .title-icon{
        font-size: 15px;
        float: left;
        margin-right: 2vh;
      }

      .tab-image-placement{
        margin-top: 3vh;
        height: 80%;
    }
      .tab-img{
        max-width: 100%;
        float: right;
        display: inline-flex;
    }

}

@media(min-width: 768px){
    .home-section2{
        padding: 8vh 6vh 8vh 6vh;
        height: auto;
    }
    .home-section2-sub{
        font-size: 18px;
        font-weight: 600;
    }
    
    .home-section2-title{
        font-size: 25px;
        line-height: 35px;
    }

    ul.homesection2 {
        display: block;
        font-size: 18px;
    }
    ul.homesection2 li {
        padding: 2vw 0 2vw 0;
        border-bottom: 5px solid transparent;
    }
    .home-section2-tab-nav{
        width: 80%;
    }
    .home-section2-tab-title{
        width: 80%;
        line-height: 25px;
    }
    ul.homesection2 li:first-child {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }
       
      ul.homesection2 li:last-child {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
    }

    .tab-align{
        margin: auto;
        text-align: justify !important;
        float: left;
        width: 100%;
        margin-right: 0;
        margin-left: 0;
    }
    .tab-content{
        padding-top: 0;
    }
    .tab-content-title{
        font-size: 30px;
        text-align: left;
        padding-bottom: 0;
        line-height: 50px;
    }
    .tab-align{
        float: left;
        width: 100%;
    }
    .title-icon{
        font-size: 15px;
        float: left;
        margin-right: 2vh;
      }

      .tab-image-placement{
        margin-top: 3vh;
        height: 80%;
    }
      .tab-img{
        max-width: 100%;
        float: right;
        display: inline-flex;
    }

    



}

@media(min-width:992px){
    .home-section2{
        padding: 8vh 6vh 8vh 6vh;
        height: auto;
    }
    .home-section2-sub{
        font-size: 18px;
        font-weight: 600;
    }
    
    .home-section2-title{
        font-size: 25px;
        line-height: 35px;
    }

    ul.homesection2 {
        display: block;
        font-size: 18px;
    }
    ul.homesection2 li {
        padding: 2vw 0 2vw 0;
        border-bottom: 5px solid transparent;
    }
    .home-section2-tab-nav{
        width: 80%;
    }
    .home-section2-tab-title{
        width: 80%;
        line-height: 25px;
    }
    ul.homesection2 li:first-child {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }
       
      ul.homesection2 li:last-child {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
    }

    .tab-align{
        margin: auto;
        text-align: justify !important;
        float: left;
        width: 100%;
        margin-right: 0;
        margin-left: 0;
    }
    .tab-content{
        padding-top: 0;
    }
    .tab-content-title{
        font-size: 30px;
        text-align: left;
        padding-bottom: 0;
        line-height: 50px;
    }
    .tab-align{
        float: left;
        width: 100%;
    }
    .title-icon{
        font-size: 15px;
        float: left;
        margin-right: 2vh;
      }

      .tab-image-placement{
        margin-top: 3vh;
        height: 80%;
    }
      .tab-img{
        max-width: 100%;
        float: right;
        display: inline-flex;
    }


}

@media(min-width:1200px) {
    .home-section2{
        min-height: 80vh;
        width: 100%;
        background-color: #f9fbfe;;
        padding: 11vh 18vh 8vh 18vh; 
    }
    
    .home-section2-sub{
        color: #b52023;
        font-size: 18px;
        font-weight: 600;
    }
    
    .home-section2-title{
        font-size: 30px;
        font-weight: 600;
        padding-top: 1vw;
        line-height: 50px;
    }
    ul.homesection2 {
        display: flex;
        color: #6e6e6e;
        background-color: #fff;
        font-size: 18px;
        line-height: 32px;
        font-weight: 500;
        transition: all 2s;
        justify-content: space-between;
        border-radius: 0.5rem;
        width:100%;
    }
    
    ul.homesection2 li {
        padding: 2vw 0 2vw 0;
        list-style: none;
        text-align: center;
        width: 100%;
        cursor: pointer;
        border-bottom: 5px solid transparent;
    }
    
      ul.homesection2 li:first-child {
        border-bottom-left-radius: 0.5rem;
        border-top-left-radius: 0.5rem;
    }
       
      ul.homesection2 li:last-child {
        border-bottom-right-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
    }
    
    ul.homesection2 li:hover {
        border-bottom: 5px solid #c11137;
        color: #C11137;
        font-weight: 600;
    
    }
    
    ul.homesection2 li.active {
        font-weight: 600;
        color: #c11137;
        border-bottom: 5px solid #c11137;
    }
    
      .title-icon{
        font-size: 35px;
        float: left;
        margin-right: 2vh;
      }
    
    .home-section2-tab-title{
        font-family: Poppins;
        font-size: 18px;
        font-weight: 500;
        line-height: 32px;
        text-align: left;
        width: 70%;
     
    }
    
    .home-section2-tab-nav{
        margin: auto;
        display: flex;
        align-items: center;
        width: 60%;
    }

    .tab-content{
        padding-top: 2vh;
        color: #303030;
    }
    
    .tab-align{
        margin: auto;
        text-align: justify !important;
        float: left;
        width: 60%;
        margin-right: 0;
        margin-left: 0;
    }
    
    .tab-image-placement{
        display: flex;
        justify-content: center;
        height: 100%;
    }
      .tab-img{
        max-width: 100%;
        float: right;
        display: inline-flex;
    }
    .tab-content-title{
        font-weight: 600;
        font-size: 30px;
        text-align: left;
        padding-bottom: 1vw;
        line-height: 60px;
    }
    .tab-content-text{
        font-weight: 400;
        font-size: 16px;
        margin-bottom: 4vw;
        line-height: 30px;
    }
    .tab-value{
        padding: 1.8vw 0 1vw 0 ;
        overflow-wrap: break-word;
        word-wrap: break-word;
        hyphens: auto;
        display: inline-block;
    }
    .value-icon{
        color: #b52023;
        font-size: 30px;
      float: left;}
    

}


