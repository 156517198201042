.portfoliodetails-section1-body{
    height: 100vh;
    width: 100%;
    background-color: #f9fbfe;
    padding: 25vh 15vh 5vh 15vh;
    color: #303030;
}

.portfoliodetails-section1-sub{
    color: #c11c11;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
}

.portfoliodetails-section1-title{
    font-size: 30px;
    font-weight: 600;
    line-height: 50px;
    width: 60%;
}

.portfoliodetails-platform{
    margin-right: 2vh;
    max-width: 10%;
}


.portfoliodetails-section1-text{
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    text-align: justify;
}

.visit-button{
    margin-top: 2vh;
    background-color: #c11c11;
    padding: 1.5vh 4vh 1.5vh 4vh !important;
    border-radius: 3vh;
    border: none;
    transition: all 0.8s;
}

.visit-button:hover{
    scale: 1.1;
    background-color: #c11c11;
}

.portfoliodetails-image{
    max-width: 15%;
    margin-top: 2vh;
    margin-bottom: 5vh;
}

@media(max-width: 576px){
    .portfoliodetails-section1-body{
        height: auto;
        padding: 10vh 5vh 5vh 5vh;
    }
    .portfoliodetails-section1-title{
        width: 100%;
    }
    .portfoliodetails-platform{
        margin-bottom: 2vh;
    }


}

@media(min-width: 576px){
    .portfoliodetails-section1-body{
        height: auto;
        padding: 10vh 5vh 5vh 5vh;
    }
    .portfoliodetails-section1-title{
        width: 100%;
    }
    .portfoliodetails-platform{
        margin-bottom: 2vh;
    }


}

@media(min-width: 768px){


}

@media(min-width:992px){


}

@media(min-width:1200px) {
    .portfoliodetails-section1-body{
        height: 100vh;
        width: 100%;
        background-color: #f0f3ff;
        padding: 25vh 15vh 5vh 15vh;
        color: #303030;
    }
    
    .portfoliodetails-section1-sub{
        color: #c11c11;
        font-weight: 600;
        font-size: 18px;
        line-height: 32px;
    }
    
    .portfoliodetails-section1-title{
        font-size: 30px;
        font-weight: 600;
        line-height: 50px;
        width: 60%;
    }
    
    .portfoliodetails-platform{
        margin-right: 2vh;
    }
    
    .portfoliodetails-section1-text{
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        text-align: justify;
    }


}