.home-section1{
    height: auto;
    width: 100%;
    background-color: #1c1c1c;
    padding: 15vh 15vh 15vh 15vh;
    color: #fff;
}

.home-section1-sub{
    color: #b52023;
    font-size: 18px;
    font-weight: 600;
}

.home-section1-title{
    font-size: 30px;
    font-weight: 600;
    line-height: 50px;
    color: #fff;
}


.home-section1-tabs {
    height: auto;
    margin: 1.5rem auto 1.5rem;
  }

  .home-section{
    text-align: center;
    justify-content: center;
    display: block;
  }

ul.homesection{
    font-size: 20px;
    color: #fff;
    padding-top: 2vw;
    padding-bottom: 2vw;
    cursor: pointer;
}

ul.homesection li{
    list-style: none;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5vh;
    width: 5vh;
    margin-top: 4vh;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid #fff;
}

ul.homesection li:hover{
    border: 1px solid #c11c11;
    color: #c11c11;
    transition: all 0.8s;
}

ul.homesection li.active{
    border: 1px solid #c11c11;
    color: #c11c11;
    transition: all 0.8s;
}


.line{
    border-left: 1px solid #fff;
    height: 30px;
    margin-top: 1.5vw;
    margin-bottom: 1.5vw;
    margin-left: 1.5vw;
    margin-right: 8.3vw;
}

.homecontent1-image-place{
    object-fit: contain;
    width: 90%;
    display: flex;
    justify-content: center;
    align-item: center;
}

.homecontent-image-section{
    max-width: 100%;
    heigth: auto;
}

.content{
    margin-top: 0vh;
}

.content-title{
    font-weight: 600;
    font-size: 34px;
    line-height: 50px;
    color: #fff;
    width: 80%;
    float: right;
    text-align: right;
    margin-top: 5vh;
}

.content-text{
    margin-top: 4vh;
    font-size: 14px;
    line-height: 28px;
    text-align: right;
    color: #fff;
    font-weight: 400;
    float: right;
    width: 70%;
}






@media(max-width: 576px){
    .home-section1{
        padding: 5vh 5vh 5vh 5vh;
        height: auto;
    }
    .home-section1-title{
        font-size: 20px;
        line-height: 30px;
    }
    ul.homesection{
    display: flex;
        text-align: center;
        justify-content: center;
        margin: 0;
        padding: 0;
    }
    ul.homesection li{
    height: 6vh;
    width: 6vh;
    margin-right: 3vh;
    }
    .line{
        display: none;
    }

    .content{
        margin-top: 0;
    }
    
    .content-title{
        font-weight: 600;
        font-size: 20px;
        line-height: 40px;
        width: 100%;
        float: left;
        text-align: left;
        margin-top: 1vh;
    }
    
    .content-text{
        margin-top: 2vh;
        line-height: 24px;
        text-align: justify;
        float: left;
        width: 100%;
    }

}

@media(min-width: 576px){
    .home-section1{
        padding: 5vh 6vh 5vh 6vh;
        height: auto;
    }
    .home-section1-title{
        font-size: 20px;
        line-height: 30px;
    }
    ul.homesection{
    display: flex;
        text-align: center;
        justify-content: center;
        margin: 0;
        padding: 0;
    }
    ul.homesection li{
    height: 5vh;
    width: 5vh;
    margin-right: 4vh;
    }
    .line{
        display: none;
    }

    .content{
        margin-top: 0;
    }
    
    .content-title{
        font-weight: 600;
        font-size: 20px;
        line-height: 50px;
        color: #fff;
        width: 100%;
        float: left;
        text-align: left;
        margin-top: 1vh;
    }
    
    .content-text{
        margin-top: 2vh;
        line-height: 24px;
        text-align: justify;
        color: #fff;
        float: left;
        width: 100%;
    }

    

}

@media(min-width: 768px){
    .home-section1{
        height: auto;
        width: 100%;
        background-color: #1c1c1c;
        padding: 5vh 8vh 10vh 8vh;
        color: #fff;
    }
     ul.homesection{
    display: flex;
        text-align: center;
        justify-content: center;
    }
    ul.homesection li{
    height: 5vh;
    width: 5vh;
    margin-right: 2vh;
    }
    .line{
        display: none;
    }

    .homecontent1-image-place{
    width: 100%;
    text-align: center;
    }

.homecontent-image-section{
    max-width: 60%;
    heigth: auto;
}


}


@media(min-width:992px){
    .home-section1{
        height: auto;
        width: 100%;
        background-color: #1c1c1c;
        padding: 10vh 10vh 10vh 10vh;
        color: #fff;
    }
    ul.homesection{
        display: flex;
        text-align: center;
        justify-content: center;
    }

    ul.homesection li{
        height: 5vh;
        width: 5vh;
        margin-top: 2vh;
        margin-bottom: 2vh;
        border-radius: 50%;
        border: 1px solid #fff;
    }

    .line{
        display: none;
    }
    .content{
        margin-top: 2vh;
    }

    .content-title{
        font-weight: 600;
        font-size: 34px;
        line-height: 50px;
        color: #fff;
        width: 80%;
        float: left;
        text-align: left;
        margin-top: 3vh;
    }
    
    .content-text{
        margin-top: 3vh;
        font-size: 14px;
        line-height: 28px;
        text-align: left;
        color: #fff;
        font-weight: 400;
        float: left;
        width: 70%;
    }




}

@media(min-width:1200px) {
    .home-section1{
    padding: 15vh 15vh 10vh 15vh;
    height: auto;
    }


    .home-section1-sub{
        color: #b52023;
        font-size: 18px;
        font-weight: 600;
    }

    .home-section1-title{
        font-size: 30px;
        font-weight: 600;
        line-height: 50px;
        color: #fff;
    }

    ul.homesection{
        display: block;
        margin: auto;
        text-align: center;
        height: 60vh;
        justify-content: center;
        padding: 5vh 4vh 2vh 4vh;
    }

    ul.homesection li{
        list-style: none;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 6vh;
        width: 6vh;
        justify-content: center;
        border-radius: 50%;
        border: 1px solid #fff;
    }

    .line{
        border-left: 1px solid #fff;
        height: 30px;
        margin-top: 1.5vw;
        margin-bottom: 1.5vw;
        display: block;
    }

    .content{
        margin-top: 8vh;
    }
    
    .content-title{
        font-weight: 600;
        font-size: 34px;
        line-height: 50px;
        color: #fff;
        width: 90%;
        float: right;
        text-align: right;
        margin-top: 4vh;
    }
    
    .content-text{
        margin-top: 4vh;
        font-size: 14px;
        line-height: 28px;
        text-align: right;
        color: #fff;
        font-weight: 400;
        float: right;
        width: 70%;
    }

    .homecontent1-image-place{
        object-fit: contain;
        width: 90%;
        display: flex;
        justify-content: center;
        align-item: center;
    }
    
    .homecontent-image-section{
        max-width: 100%;
        heigth: auto;
    }




}
