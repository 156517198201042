.footer-background{
    width: 100%;
    height: auto;
    color: #fff;
    background: linear-gradient(to right, rgba(28, 28, 28, 0.9), rgba(28, 28, 28, 0.9)), url(/src/assets/rec_1.png);
    padding: 15vh;
    background-size: cover;
}

.title{
    margin-top: 20vh;
    margin-bottom: 15vh;
    font-size: 100px;
    width: 80%;
    font-weight: 500;
    background-image: linear-gradient(#fff, #fff);
    background-size: 0% 0.2vh;
    background-position-y: 100%;
    background-position-x: 0%;
    background-repeat: no-repeat;
    transition: background-size 0.2s ease-in-out;
}

.title:hover,
.title:focus,
.title:active {
background-size: 100% 0.2vh;
}

.text-decoration-none-footer{
    text-decoration: none;
    color: #fff;
}

.text-decoration-none-footer:hover{
    color: #fff;
}


.footer-text{
    font-size: 20px;
    font-weight: 700;
}

.links{
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    margin-top: 5vh;
}

.footer-text-decoration{
        color: #fff;
        text-decoration: none;
        transition: all 0.8s;
}

.footer-text-decoration:hover{
    color: #c11c11;
}

.footer-link-place{
    display: inline-block;
    justify-content: center;
    margin-left: 10vh;
}

.footer-link-place li{
    list-style: none;
    padding-bottom: 2vh;
}

.footer-links{
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
    text-decoration: none;
    color: #fff;
    margin-right: 2vw;
}


.footer-links:hover{
    color: #c11c11;
}

.footer-icons{
    margin-right: 1vh;
    font-size: 30px;
    color: #fff;
    transition: all 0.8s;
}

.footer-icons:hover{
    color: #c11c11;
}

.footer-links-align{
    margin:auto;
    justify-content: center;
    align-items: center;
}

.footer-bottom{
    background-color: #1c1c1c;
    padding: 2vh 10vh;
    color: #fff;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
}


@media(max-width: 576px){
    .footer-background{
        background: linear-gradient(to right, rgba(28, 28, 28, 0.9), rgba(28, 28, 28, 0.9)), url(/src/assets/rec_1.png) no-repeat center fixed;
        padding: 5vh;
        background-size: 100% 100%;
    }
    .title{
        margin-top: 5vh;
        margin-bottom: 15vh;
        font-size: 70px;
        font-weight: 500;
    }
    .links{
        margin-top: 2vh;
        margin-bottom: 2vh;
    }
    .footer-link-place{
        display: inline-block;
        justify-content: center;
        margin: auto;
    }



}

@media(min-width: 576px){
    .footer-background{
    background: linear-gradient(to right, rgba(28, 28, 28, 0.9), rgba(28, 28, 28, 0.9)), url(/src/assets/rec_1.png) no-repeat center fixed;
    padding: 5vh;
    background-size: 100% 100%;
    }
    .title{
        margin-top: 5vh;
        margin-bottom: 15vh;
        font-size: 70px;
        font-weight: 500;
    }
    .links{
        margin-top: 2vh;
        margin-bottom: 2vh;
    }
    .footer-link-place{
        display: inline-block;
        justify-content: center;
        margin: auto;
    }

}

@media(min-width: 768px){
    .footer-background{
    background: linear-gradient(to right, rgba(28, 28, 28, 0.9), rgba(28, 28, 28, 0.9)), url(/src/assets/rec_1.png) no-repeat center fixed;
    padding: 5vh;
    background-size: 100% 100%;
    }
    .title{
        margin-top: 5vh;
        margin-bottom: 15vh;
        font-size: 70px;
        font-weight: 500;
    }
    .links{
        margin-top: 2vh;
        margin-bottom: 2vh;
    }
    .footer-link-place{
        display: inline-block;
        justify-content: center;
        margin: auto;
        align-items: center;
    }


}

@media(min-width:992px){
        .footer-background{
    background: linear-gradient(to right, rgba(28, 28, 28, 0.9), rgba(28, 28, 28, 0.9)), url(/src/assets/rec_1.png) no-repeat center fixed;
    padding: 5vh;
    background-size: 100% 100%;
    }
    .title{
        margin-top: 5vh;
        margin-bottom: 15vh;
        font-size: 70px;
        font-weight: 500;
    }
    .links{
        margin-top: 2vh;
        margin-bottom: 2vh;
    }
    .footer-link-place{
        display: inline-block;
        justify-content: center;
        margin: auto;
        align-items: center;
    }

}

@media(min-width:1200px) {
    .footer-background{
    width: 100%;
    height: auto;
    color: #fff;
    background: linear-gradient(to right, rgba(28, 28, 28, 0.9), rgba(28, 28, 28, 0.9)), url(/src/assets/rec_1.png);
    padding: 15vh;
    background-size: cover;
    }

    .title{
        margin-top: 20vh;
        margin-bottom: 15vh;
        font-size: 100px;
        font-weight: 500;
    }

    .footer-text{
        font-size: 20px;
        font-weight: 700;
    }

    .links{
        font-size: 16px;
        font-weight: 400;
        line-height: 30px;
        margin-top: 5vh;
    }

    .footer-link-place{
        display: inline-block;
        justify-content: center;
        margin-left: 10vh;
    }

    .footer-link-place li{
        list-style: none;
        padding-bottom: 2vh;
    }

    .footer-links{
        font-size: 16px;
        line-height: 30px;
        font-weight: 400;
        text-decoration: none;
        color: #fff;
        margin-right: 2vw;
    }

    .footer-icons{
        margin-right: 1vh;
        font-size: 25px;
    }

    .footer-links-align{
        margin:auto;
        justify-content: center;
        align-items: center;
    }


}