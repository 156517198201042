.portfoliodetails-section6-body{
    width: 100%;
    height: 100vh;
    background: url(/src/assets/rec_1.png) no-repeat top fixed;
    background-size: 100% 100%;
    color: #fff;
    padding: 18vh 10vh 15vh 10vh;
}

.portfoliodetails-links{
    font-size: 18px;
    line-height: 32px;
    font-weight: 600;
}

.text-decoration-portfoliodetails-next{
    text-decoration: none;
}

@media(max-width: 576px){
    .portfoliodetails-section6-body{
        height: auto;
        padding: 15vh 5vh 15vh 5vh;
    }


}

@media(min-width: 576px){
    .portfoliodetails-section6-body{
        height: auto;
        padding: 15vh 5vh 15vh 5vh;
        background-size: 100% 100%;
    }

}

@media(min-width: 768px){


}

@media(min-width:992px){


}

@media(min-width:1200px) {
    .portfoliodetails-section6-body{
        width: 100%;
        height: 100vh;
        background: url(/src/assets/rec_1.png) no-repeat top fixed;
        background-size: 100% 100%;
        color: #fff;
        padding: 18vh 10vh 15vh 10vh;
    }


}

